import { useState, useEffect } from 'react';
import { collection, addDoc, serverTimestamp, doc, getDoc, query, where } from 'firebase/firestore';
import { db } from './LogInn/firebase-config';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { useHistory } from 'react-router-dom';

import './LogInn/StylesLogin.css';

const Registrer = () => {
  const [user, setUser] = useState(null);
  const [isVisitRegistered, setIsVisitRegistered] = useState(false);
  const auth = getAuth();
  const history = useHistory();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
    });
    return unsubscribe;
  }, [auth]);

  const queryParameters = new URLSearchParams(window.location.search);
  const postId = queryParameters.get('post');

  const isValidPostId = postId && /^[a-zA-Z0-9]{20}$/.test(postId);

  const checkIfVisitAlreadyRegistered = async () => {
    if (!user) return;
    const userId = user.uid;
    const visitsRef = collection(db, 'visits');
    const q = query(visitsRef, where('post', '==', postId), where('userId', '==', userId));
    const querySnapshot = await getDoc(q);
    if (querySnapshot.size > 0) {
      setIsVisitRegistered(true);
    }
  };

  useEffect(() => {
    checkIfVisitAlreadyRegistered();
  }, [user]);

  const registerBesok = async () => {
    if (!user) {
      window.sessionStorage.setItem('redirectUrl', '/Registrer?post=' + postId);
      history.push('/login');
      return;
    }
    try {
      const userId = user.uid;
      const postDocRef = doc(db, 'posts', postId);
      const postDocSnap = await getDoc(postDocRef);

      if (postDocSnap.exists()) {
        const visitDocRef = await addDoc(collection(db, 'visits'), {
          post: postId,
          time: serverTimestamp(),
          userId,
        });
        console.log('Document written with ID: ', visitDocRef.id);
        setIsVisitRegistered(true);
      } else {
        console.error(`No post found with ID: ${postId}`);
      }
    } catch (error) {
      console.error('Error writing document: ', error);
    }
  };

  function HandleGoBack () {
    history.push('/tab2')
  }

  return (
    <html>
      <div className='loginstuff'>
        {isValidPostId ? (
          <>
            <p>Post: {postId}</p>
            {isVisitRegistered ? (
              <p style={{color: 'green'}}>Besøk registrert!</p>
            ) : (
              <button onClick={registerBesok} disabled={isVisitRegistered}>Register besøk</button>
            )}
          </>
        ) : (
          <p style={{color: 'red'}}>Feil post ID i URL</p>
        )}
        {user ? (
          <p> Du er logget inn med: {user.email}</p>
        ) : (
          <p style={{color: 'red'}}>Du må logge inn for å registrere ett besøk</p>
        )}
        <button onClick={HandleGoBack}>Tilbake til forside</button>
      </div>
    </html>
);
};

export default Registrer;




