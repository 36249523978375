import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonLabel, IonButton, IonItem, IonIcon, IonText, IonInput } from '@ionic/react';
import { flagOutline, returnDownBackOutline } from 'ionicons/icons';
import './styles.css';
import {chevronForwardOutline, chevronBackOutline }   from 'ionicons/icons';


const Hostovatnet_rundt: React.FC = () => {
 

  return (
    <IonPage >
      <body className="poster-page ion-padding">

   
   
    <h1>Post 8 - Hostovatnet rundt</h1>
    <div >


    
      <p>
      Det er parkering og start ved Hoston Storstue i Hoston.
      </p>
  
      <p>
      Start å gå i retning med klokka/sola i retning Storås. Ta av fylkesveien til høyre
ved Øyasætra.
      </p>
 
      <p>
      Det er skilta opp til Gjømsan/Gimsan
etter å ha passert Vaulen. NB! På
Statens Kartverk brukes navnene
Gimsan, Gimshøgda, men
turskiltprosjektet i Hoston har fått
tillatelse til å bruke Gjømsan og
Gjømshøgda.
      </p>
      Så passeres først Gjømsan og deretter
Kusætra, her er posten plassert. Fra
Kusætra er det merkede ruter til
Gjømsan og Gjømshøgda. Følger så
Kusætras gårdsvei, og tar til høyre når
en kommer inn på Meåsveien. Derfra
følges Meåsveien og Hostonveien
tilbake til Hoston Storstue.
      <p>
      Hele turen er på 9,3 km og kan sykles om ønskelig. Hostovatnet er 3,14 km2
stort, har en gjennomsnittlig dybde på 8 m, ligger 199 moh og har ei strandlinje på
10,31 km.
      </p>
      <h6><strong>Bilder:</strong></h6>
      <img src={`${process.env.PUBLIC_URL}/assets/icon/hostvatnet2.png`} alt="hostvatnet2" />
      <img src={`${process.env.PUBLIC_URL}/assets/icon/hostvatnet.png`} alt="hostvatnet" />
      </div >
      
    
              
        
 

  <IonButton  href='tab2'>Tilbake til HovedSide</IonButton>

  <IonButton  href='MyMap'>Se posten på Kartet</IonButton>

  
  <div className="button-wrapper">
  <IonButton href='Stuggudalstjonnin'>forrige post
  <IonIcon slot="start" icon={chevronBackOutline} />  
  </IonButton>
  <IonButton href='Hogberget'>neste post
  <IonIcon slot="end" icon={chevronForwardOutline} />
  </IonButton>
  </div>

</body>
</IonPage>
  );
};

export default Hostovatnet_rundt;