import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonLabel, IonButton, IonItem, IonIcon, IonText, IonInput } from '@ionic/react';
import { flagOutline, returnDownBackOutline } from 'ionicons/icons';
import './styles.css';
import {chevronForwardOutline, chevronBackOutline }   from 'ionicons/icons';


const Svartholhausen: React.FC = () => {
 

  return (
    <IonPage >
      <body className="poster-page ion-padding">

   
   
    <h1>Post 5 – Svartholhausen</h1>
    <div> 

      <p>
      Kjør Fv 6484 til Høgkjølen, så E 39 et lite stykke til Bjørkøybekk. Parker der.
Avgift kr. 50,-.
      </p>
  
      <p>
      Gå veien opp til krysset der veien til Fjellkirka tar til høyre. Fortsett etter merkene
oppover mot «Gryta». Her er det en del myr, men forholdsvis slakt terreng.
Drei mot høyre bak Kneppfjellet. Derfra går det bratt oppover til toppen. Her er
det fastere underlag, mer og mer fjellterreng. Varde på toppen.
      </p>
 
      <p>
      GTraseen følger omtrent skiløypa til Omnfjellet. Forholdsvis lang og tung tur.
Belønningen er en fantastisk utsikt på toppen. Her ser vi Snota og de store fjella i
Trollheimen, Søvatnet med Ruten i bakgrunnen og vidt utsyn i alle retninger.
      </p>
    
      <p>
      Toppen ligger 808moh. 10 km tur retur, nesten 500 m stigning.
      </p>
      <h6><strong>Bilder:</strong></h6>
      <img src={`${process.env.PUBLIC_URL}/assets/icon/svartholhausen.png`} alt="svartholhausen" />
      <img src={`${process.env.PUBLIC_URL}/assets/icon/svartholhausen2.png`} alt="svartholhausen2" />
</div>
      
    
              
        
  
  <IonButton href='tab2'>Tilbake til HovedSide</IonButton>
  
  <IonButton href='MyMap'>Se posten på Kartet</IonButton>
  
 
 <div className="button-wrapper">
  <IonButton href='Rundhaugen'>forrige post
  <IonIcon slot="start" icon={chevronBackOutline} />  
  </IonButton>

  <IonButton href='Vardfjellet'>neste post
  <IonIcon slot="end" icon={chevronForwardOutline}  />
  </IonButton>
  </div>

</body>
</IonPage>
  );
};

export default Svartholhausen;