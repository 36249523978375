import { initializeApp } from "firebase/app";
import {getFirestore} from "@firebase/firestore";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";


const firebaseConfig = {
    apiKey: "AIzaSyCb6xuvgix-tuBuAtP-Xk_ugoEoyvIst9g",
    authDomain: "kjentmannsmerketdatabase.firebaseapp.com",
    projectId: "kjentmannsmerketdatabase",
    storageBucket: "kjentmannsmerketdatabase.appspot.com",
    messagingSenderId: "1026964119488",
    appId: "1:1026964119488:web:0245c75f372d8e2a8d3225"
  };

  const app = initializeApp(firebaseConfig);

 export const db = getFirestore(app);

 export default firebaseConfig;