import { useState } from 'react';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import { useHistory } from "react-router-dom";
import './StylesLogin.css';

function SignUp() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const history = useHistory();

  const handleSignUp = () => {
    const auth = getAuth();

    // Check if email is valid
    if (!validateEmail(email)) {
      setErrorMessage('Ikke gyldig e-post-adresse');
      setSuccessMessage(null);
      return;
    }

    // Check if passwords match
    if (password !== confirmPassword) {
      setErrorMessage('Passordene stemmer ikke');
      setSuccessMessage(null);
      return;
    }

    // Create user with email and password
    createUserWithEmailAndPassword(auth, email, password)
      .then(() => {
        setSuccessMessage('Brukeren ble registrert!');
        setEmail('');
        setPassword('');
        setConfirmPassword('');
        setErrorMessage(null);
      })
      .catch((error) => {
        setErrorMessage(error.message);
        setSuccessMessage(null);
      });
  };

  const validateEmail = (email) => {
    // Source: https://stackoverflow.com/a/46181/1399895
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <div className='loginstuff'>
      <h1>Registrer ny bruker</h1>
      <div>
        <label>E-post:</label>
        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
      </div>
      <div>
        <label>Passord:</label>
        <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
      </div>
      <div>
        <label>Bekreft Passord:</label>
        <input type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
      </div>
      <button onClick={handleSignUp}>Registrer Bruker</button>
      {errorMessage && <p type="errormessage" >{errorMessage}</p>}
      {successMessage && <p style={{color: "green"}} >{successMessage}</p>}
      <p>Allerede registrert? Logg inn her:</p>
      <button style={{ marginTop: "20px" }} onClick={() => history.push('/LogIn')}>
    Logg inn
      </button>
    </div>
  );
}

export default SignUp;
