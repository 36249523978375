// Component Imports

import {
  IonApp,  
  IonHeader,  
  IonIcon, 
  IonLabel, 
  IonRoute, 
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact
} from '@ionic/react';

import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route, useHistory } from 'react-router-dom'; 
import { useEffect, useState } from "react";
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { User } from 'firebase/auth';



//Page Imports

import Tab1 from './pages/Tab1';
import Tab2 from './pages/Tab2';
import Tab3 from './pages/Tab3';
import MyMap from './pages/MapPage';
import MinProfil from './pages/MinProfil'
import Poster from './pages/Poster';
import PraktiskeOpplysninger from './pages/PraktiskeOpplysninger';
import EndreInfo from './pages/EndreInfo';
import WelcomePage from './pages/LogInn/WelcomePage';
import LogIn from './pages/LogInn/LogIn';
import SignUp from './pages/LogInn/SignUp';
import LagetAv from './pages/LagetAv';
import Registrer from './pages/Registrer';

//Post Imports

import Furtjonna from './pages/Post_informasjon/Furtjonna';
import Djupdalsfjellet from './pages/Post_informasjon/Djupdalsfjellet';
import Storlia from './pages/Post_informasjon/Storlia';
import Ellingsgardstjonna from './pages/Post_informasjon/Ellingsgardstjonna';
import Hogberget from './pages/Post_informasjon/Hogberget';
import Hostovatnet_rundt from './pages/Post_informasjon/Hostovatnet_rundt';
import Pilegrimsleia from './pages/Post_informasjon/Pilegrimsleia';
import Rundhaugen from './pages/Post_informasjon/Rundhaugen';
import Stuggudalstjonnin from './pages/Post_informasjon/Stuggudalstjonnin';
import Svartholhausen from './pages/Post_informasjon/Svartholhausen';
import Vardfjellet from './pages/Post_informasjon/Vardfjellet';
import Storfurua from './pages/Post_informasjon/Storfurua';

import './App.css';
import './Kart.css';

// Ikon Imports

import { ellipse, moon, square, triangle } from 'ionicons/icons';
import { locationSharp, mapOutline, settingsOutline, homeOutline } from 'ionicons/icons';


/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';




setupIonicReact();

const App: React.FC = () => {
  
 
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const history = useHistory();

  useEffect(() => {
    const auth = getAuth();

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
    });
    return unsubscribe;
  }, []);



return( 


  <IonApp>
  <IonReactRouter>
    <IonRouterOutlet>
      {/* Route for welcome page */}
      <Route exact path="/">
        <Redirect to="/WelcomePage" />
      </Route>

      <Route exact path="/WelcomePage">
        <WelcomePage />
      </Route>

      <Route exact path="/SignUp">
        <SignUp />
      </Route>

      <Route exact path="/LogIn">
        <LogIn />
      </Route>

      {/* Main App */}
      <IonTabs>
        <IonRouterOutlet>
        
          <Route exact path="/tab1">
            <Tab1 />
          </Route>

          <Route exact path="/tab2">
            <Tab2 />
          </Route>

          <Route exact path="/tab3">
            <Tab3 />
          </Route>

          <Route exact path="/MinProfil">
            <MinProfil />
          </Route>

          <Route exact path="/Poster">
            <Poster />
          </Route>

          <Route exact path="/PraktiskeOpplysninger">
            <PraktiskeOpplysninger />
          </Route>

          {/* Post Routes */}
          <Route exact path="/Furtjonna">
            <Furtjonna />
          </Route>

          <Route exact path="/Djupdalsfjellet">
            <Djupdalsfjellet />
          </Route>

          <Route exact path="/Ellingsgardstjonna">
            <Ellingsgardstjonna />
          </Route>

          <Route exact path="/Rundhaugen">
            <Rundhaugen />
          </Route>

          <Route exact path="/Svartholhausen">
            <Svartholhausen />
          </Route>

          <Route exact path="/Vardfjellet">
            <Vardfjellet />
          </Route>

          <Route exact path="/Stuggudalstjonnin">
            <Stuggudalstjonnin />
          </Route>

          <Route exact path="/Hostovatnet_rundt">
            <Hostovatnet_rundt />
          </Route>

          <Route exact path="/Hogberget"> 
            <Hogberget />           
          </Route> 

          <Route exact path="/Pilegrimsleia"> 
            <Pilegrimsleia />           
          </Route> 

          <Route exact path="/Storlia"> 
            < Storlia />           
          </Route> 
          
          <Route exact path="/Storfurua"> 
            < Storfurua />           
          </Route> 

          <Route exact path="/EndreInfo"> 
            <EndreInfo />           
          </Route>

          <Route exact path="/LagetAv"> 
            <LagetAv />           
          </Route>

          <Route exact path="/MyMap"> 
             <MyMap />           
           </Route>

           <Route exact path="/Registrer"> 
             <Registrer />           
           </Route> 

        </IonRouterOutlet>
        

        {/* Tab bar */}

        <IonTabBar slot="bottom">
          <IonTabButton tab="tab1" href="/MyMap" >
            <IonIcon icon={mapOutline} />
            <IonLabel>Kart</IonLabel>
          </IonTabButton>

          <IonTabButton tab="tab2" href="/tab2">
            <IonIcon icon={homeOutline} />
            <IonLabel>Hjem</IonLabel>
          </IonTabButton>

          <IonTabButton tab="tab3" href="/tab3">
            <IonIcon icon={settingsOutline} />
            <IonLabel>Innstillinger</IonLabel>
          </IonTabButton>
        </IonTabBar>
      </IonTabs>
    </IonRouterOutlet>
  </IonReactRouter>
</IonApp>
);

};
export default App;

