import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonLabel, IonButton, IonItem, IonIcon, IonText, IonInput } from '@ionic/react';
import './styles.css';
import {chevronForwardOutline, chevronBackOutline }   from 'ionicons/icons';



const Djupdalsfjellet: React.FC = () => {
 

  return (
    <IonPage >
      <body className="poster-page ion-padding">

    <h1>Post 2 – Djupdalsfjellet</h1>
  <div >
   
      <h6><strong>Veibeskrivelse:</strong></h6>
      <p>
      Du kjører fra Orkanger og
følger vei 710 ca 22 kilometer
mot Agdenes. Rett etter skilt
Ingdalen er det skiltet
Kjentmannspost Parkering
ved Solberg gård. Turen går
da innover en skogsvei og
etter hvert sti. Følg merka
løype.
<br></br>
Turen er rundt 4 km tur/retur.
Det er et par plasser med
blaut myr. Anbefaler fjellsko
eller støvler. Djupdalsfjellet
ligger 247 moh og du får en
fantastisk utsikt over
Trondheimsleia. Turen passer
godt for barnefamilier. En flott
tur!
      </p>
      <h6><strong>Bilder:</strong></h6>
      <img src={`${process.env.PUBLIC_URL}/assets/icon/djupdalsfjellet.png`} alt="djupdalsfjellet" />
      <img src={`${process.env.PUBLIC_URL}/assets/icon/djupdalsfjellet2.png`} alt="djupdalsfjellet2" />
  </div >


  <IonButton  href='tab2'>Tilbake til HovedSide</IonButton>
  <IonButton  href='MyMap'>Se posten på Kartet</IonButton>


  <div className="button-wrapper">
    <IonButton href='Furtjonna'>forrige post
    <IonIcon slot="start" icon={chevronBackOutline}  />
    </IonButton>
   

  
    <IonButton href='Ellingsgardstjonna'>neste post
    <IonIcon slot="end" icon={chevronForwardOutline}  />
    </IonButton>
  </div>
  

</body>
</IonPage>
  );
};

export default Djupdalsfjellet;