import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonLabel, IonButton, IonItem, IonIcon, IonText, IonInput } from '@ionic/react';
import { flagOutline, returnDownBackOutline } from 'ionicons/icons';
import './styles.css';
import {chevronForwardOutline, chevronBackOutline }   from 'ionicons/icons';


const Storfurua: React.FC = () => {
 

  return (
    
    <IonPage >
    <body className="poster-page ion-padding">

  
    <h1>Post 12 – Storfurua i Husdalen</h1>
    <div >
   




    <h6><strong>Veibeskrivelse:</strong></h6>

      <p>
      Kjør FV 710 til Geitastranda og ta av på
Husdalsvegen. Følg Husdalsvegen i ca
5 km og parker ved «nybrua» over
Husdalselva.
      </p>
  
      <h6><strong>Postbeskrivelse:</strong></h6>
      <p>
      Gå ca 200 meter tilbake langs veien og
følg merkene. Frem til Åstsætra går
turen langs en skogsbilvei. Ved
Åstsætra er det ruiner etter
sæterbygninger. Her er det også benker
hvor man kan sitte. Ta av og følg stien
til du kommer til Storfurua. Selve
Kjentmannsposten har vi lagt litt forbi
Storfurua. Stien går gjennom plantefelt
så vi tar hensyn og holder oss på stien.
Post 12 – Storfurua i Husdalen
<br></br>
15
Her får du en flott utsikt over Husdalsvatnet og mot Rabbolen. Det er ca
100 m stigning fra parkeringen og frem til posten. Total distanse tur/retur:
Ca 4 km.
      </p>
      <h6><strong>Bilder:</strong></h6>
      <img src={`${process.env.PUBLIC_URL}/assets/icon/storfurua.png`} alt="storfurua" />
      <img src={`${process.env.PUBLIC_URL}/assets/icon/storfurua2.png`} alt="storfurua2" />
      </div >


  <IonButton  href='tab2'>Tilbake til HovedSide</IonButton>

  <IonButton  href='MyMap'>Se posten på Kartet</IonButton>

  
  <div className="button-wrapper">
  <IonButton href='Storlia'>forrige post
  <IonIcon slot="start" icon={chevronBackOutline}  />  
  </IonButton>
  </div>

</body>
</IonPage>
  );
};

export default Storfurua;