

import './LogInn/StylesLogin.css';

import { useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged, User, signOut } from 'firebase/auth';
import { useHistory } from "react-router-dom";
import SignInTest from './LogInn/LogIn';
import {  IonButton, IonCol, IonContent, IonHeader, IonItem, IonLabel, IonList, IonPage, IonTitle, IonToolbar, IonGrid, IonToggle, IonIcon, IonicSafeString } from '@ionic/react';
import { chevronForwardOutline ,logOutOutline, createOutline } from 'ionicons/icons';

const Tab3: React.FC = () => {
  
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const history = useHistory();

  useEffect(() => {
    const auth = getAuth();

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
    });

    return unsubscribe;
  }, []);


  const handleSignOut = () => {
    const auth = getAuth();
    auth.signOut();
    history.push("/LogIn");
    window.location.reload();
  };

  return (
    <IonPage >
      <IonHeader >
        <IonToolbar >
          <IonTitle >Innstillinger</IonTitle>
        </IonToolbar>
      </IonHeader>
     <IonContent>
        <IonList >

        <IonItem>
          <IonLabel >Laget Av</IonLabel>
          <IonButton style={{ width: '50px', height:'35px' }} color="tertiary" href="/lagetAv" >
          <IonIcon icon={chevronForwardOutline} size="large"></IonIcon>
          </IonButton>
          </IonItem>


        </IonList>
        
      
        
          <div className='tab3' >
            <h1>Informasjon angående brukerdata:</h1>
            <p>For å endre brukernavn, passord eller om du har brukerproblemer vennligst
              kontakt kjentmannsmerket på facebook, eller tlf.
            </p>


      {currentUser ? (
        <div >
          <br></br>
          <p>Du er logget inn med: {currentUser.email}</p>
          <div >
          <button onClick={handleSignOut}>Logg ut</button>
          </div>
        </div>
      ) : (
        <p>Du er ikke logget inn.</p>
      )}
    </div>

 
     </IonContent>
  </IonPage>
  );
};



export default Tab3;
