import { TileLayer,Layer, Marker, Popup, LayersControl, ZoomControl, CircleMarker, useMapEvents} from "react-leaflet";
import {IonButton} from '@ionic/react';
import { useEffect, useRef } from 'react';
import { MapContainer, useMap } from 'react-leaflet';
import { Icon } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import './MapPage.css';


import {  useState } from 'react';

import L from "leaflet";
import icon from "./Constants";

const center = [63.30685327471731, 9.67929151904978]; // center (Orkanger)
const furtjønna = [	63.3625042, 9.7228154];         
const Djupdalsfjellet = [63.4421289, 	9.9234382]
const Ellingsgardstjønna = [63.3319149, 9.679041]
const Rundhaugen = [63.2938199, 9.5803347]
const Svartholhausen = [63.27396, 9.4979401]
const Vardfjellet = [63.2178086, 9.4795803]
const stuggudalstjønnin = [63.1963583, 	9.432375]
const HostovatnetRundt = [63.1955356,  9.5517917]
const Høgberget = [63.1751999, 9.7172576]
const PilgrimsleiaFraHongslo = [63.1870147, 9.8364716]
const Berglia = [	63.2236243, 9.9167135]
const StorfuruaIHusdalen = [ 63.355382, 9.8357704]

var blueMarker = new Icon({
  iconUrl: 'https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});


function MyMap() {


/* Geolocation kode
const [userLocation, setUserLocation] = useState(null);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      setUserLocation([position.coords.latitude, position.coords.longitude]);
    });
  }, []);

  function LocationMarker() {
    const map = useMapEvents({
      locationfound(e) {
        setUserLocation([e.latlng.lat, e.latlng.lng]);
        map.flyTo(e.latlng, map.getZoom());
      },
      enableHighAccuracy: true,
      watch: true,
      maxZoom: 20
    });

    function LocationMarker(){
      const [position, setPosition] = useState(null);
      const [bbox, setBbox] = useState([]);
  
     const map = useMap();
  
      useEffect(() => {
       map.locate().on("locationfound", function (e) {
          setPosition(e.latlng);
          map.flyTo(e.latlng, map.getZoom());
          const radius = e.accuracy;
          const circle = L.circle(e.latlng, radius);
         
          setBbox(e.bounds.toBBoxString().split(","));
         });
     }, [map]);
  
   
   }
    return userLocation === null ? null : (
      <CircleMarker center={userLocation} radius={10}>
        
        <Popup>Du er her</Popup>
        
      </CircleMarker>
    );
  }

*/
 
  return (
    <>
    <MapContainer className="map" class="openlayers-map"
    center={center} 
    zoom={9}  
   zoomControl={0}  
    >
    <LayersControl position="topright" className="leaflet-control-layers"  >
      <LayersControl.BaseLayer name="Kart">

      <TileLayer 
        attribution='&copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        
      />
      
      <ZoomControl position="bottomright" className="leaflet-control-zoom"/>

      </LayersControl.BaseLayer>

<LayersControl.BaseLayer checked name="Terreng">

<TileLayer
  url='http://{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}' 
  subdomains={['mt0','mt1','mt2','mt3']}
/>

</LayersControl.BaseLayer>

<LayersControl.BaseLayer name="Satellitt" >
  <input type="radio" />
<TileLayer
  url='http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}' 
  subdomains={['mt0','mt1','mt2','mt3']}
/>
</LayersControl.BaseLayer>
</LayersControl>

      <Marker position={furtjønna} icon={blueMarker} >
      
        <Popup>
          Her ligger Post 1 - furtjønna
        <IonButton
         color="primary" href="/Furtjonna">
          Info
        </IonButton>
        </Popup>
      </Marker>
  
      <Marker position={Djupdalsfjellet} icon={blueMarker}>
        <Popup>
          Her ligger Post 2 - Djupdalsfjellet
          <IonButton 
         color="primary" href="/Djupdalsfjellet">
          Info
        </IonButton>
        </Popup>
      </Marker>

      <Marker position={Ellingsgardstjønna} icon={blueMarker}>
        <Popup>
          Her ligger Post 3 - Ellingsgardstjønna
          <IonButton
        color="primary" href="/Ellingsgardstjonna">
          Info
        </IonButton>
        </Popup>
      </Marker>

      <Marker position={Rundhaugen} icon={blueMarker}>
        <Popup>
          Her ligger Post 4 - Rundhaugen
          <IonButton
        color="primary" href="/Rundhaugen">
          Info
        </IonButton>
        </Popup>
      </Marker>

      <Marker position={Svartholhausen} icon={blueMarker}>
        <Popup>
          Her ligger Post 5 - Svartholhausen
          <IonButton
         color="primary" href="/Svartholhausen">
          Info
        </IonButton>
        </Popup>
      </Marker>

      <Marker position={Vardfjellet} icon={blueMarker}>
        <Popup>
          Her ligger Post 6 - Vardfjellet
          <IonButton
        color="primary" href="/Vardfjellet">
          Info
        </IonButton>
        </Popup>
      </Marker>

      <Marker position={stuggudalstjønnin} icon={blueMarker}>
        <Popup>
          Her ligger Post 7 - stuggudalstjønnin
          <IonButton
        color="primary" href="/stuggudalstjonnin">
          Info
        </IonButton>
        </Popup>
      </Marker>

      <Marker position={HostovatnetRundt} icon={blueMarker}>
        <Popup>
          Her ligger Post 8 - Hostovatnet Rundt
          <IonButton
       color="primary" href="/Hostovatnet_Rundt">
          Info
        </IonButton>
        </Popup>
      </Marker>

      <Marker position={Høgberget} icon={blueMarker}>
        <Popup>
          Her ligger Post 9 - Høgberget
          <IonButton
        color="primary" href="/Hogberget">
          Info
        </IonButton>
        </Popup>
      </Marker>

      <Marker position={PilgrimsleiaFraHongslo} icon={blueMarker}>
        <Popup>
          Her ligger Post 10 - Pilegrimsleia Fra Hongslo
          <IonButton
         color="primary" href="/Pilegrimsleia">
          Info
        </IonButton>
        </Popup>
      </Marker>

      <Marker position={Berglia} icon={blueMarker} >
        <Popup>
          Her ligger Post 11 - Storlia
          <IonButton
        color="primary" href="/Storlia">
          Info
        </IonButton>
        </Popup>
      </Marker>

      <Marker position={StorfuruaIHusdalen} icon={blueMarker}>
        <Popup>
          Her ligger Post 12 - Storfurua i Husdalen
          <IonButton
        color="primary" href="/Storfurua">
          Info
        </IonButton>
        </Popup>
      </Marker>

      {/* <LocationMarker /> */}
     
</MapContainer>
</>
  );
}

export default MyMap;