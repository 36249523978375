import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonLabel, IonButton, IonItem, IonIcon, IonText, IonInput } from '@ionic/react';
import { flagOutline, returnDownBackOutline } from 'ionicons/icons';
import './styles.css';
import {chevronForwardOutline, chevronBackOutline }   from 'ionicons/icons';


const Vardfjellet: React.FC = () => {
 

  return (
    <IonPage>
   <body className="poster-page ion-padding">
   
  
    <h1>Post 6 – Vardfjellet</h1>
    <div >



    
      <h6><strong>Beskrivelse:</strong></h6>
      <p>
      Kjør Fv 6484 til Høgkjølen, så E 39 et lite stykke til P-plass mellom
Bjørkøybekk og Hundsåa. Den ligger på venstre side av veien, like før Ytter
Svorksjøveien.
      </p>
  
      <p>
      Følg veien fra parkeringsplassen nesten til enden. Fint å gå eller sykle.
Følg merkene til varden på toppen. Over en del myr fra veien, kommer inn
på sti etter hvert. Posten er ved varden, men det anbefales å gå vestover
langs toppen slik at du kan få utsikt over Søvatnet og Ruten.
      </p>
 
      <p>
      Også mulig å gå til høyre i krysset ved Eggamosætra og ta toppen fra
andre sida, langs Ytter Hyllbekken. Den blir ikke merket av
Kjentmannsmerket.
      </p>
    
      <p>
      7 km tur /retur. Nesten 300 m stigning. Toppen er ca. 600 moh.
      </p>
      <h6><strong>Bilder:</strong></h6>
      <img src={`${process.env.PUBLIC_URL}/assets/icon/vardfjellet.png`} alt="vardfjellet" />
      <img src={`${process.env.PUBLIC_URL}/assets/icon/vardfjellet2.png`} alt="vardfjellet2" />
      </div >
      
    
              
        
 

  <IonButton  href='tab2'>Tilbake til HovedSide</IonButton>
 
  <IonButton  href='MyMap'>Se posten på Kartet</IonButton>
  
  
  <div className="button-wrapper">
  <IonButton href='Svartholhausen'>forrige post
  <IonIcon slot="start" icon={chevronBackOutline} />  
  </IonButton>
  <IonButton href='Stuggudalstjonnin'>neste post
  <IonIcon slot="end" icon={chevronForwardOutline}  />
  </IonButton>
  </div>

</body>
</IonPage>
  );
};

export default Vardfjellet;