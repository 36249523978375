import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonLabel, IonButton, IonItem, IonIcon, IonText, IonInput } from '@ionic/react';
import { flagOutline, returnDownBackOutline } from 'ionicons/icons';
import './styles.css';
import {chevronForwardOutline, chevronBackOutline }   from 'ionicons/icons';


const Storlia: React.FC = () => {
 

  return (
 
<IonPage >
      <body className="poster-page ion-padding">

  
    <h1>Post 11 – Storlia</h1>
    
  <div >




    <h6><strong>Veibeskrivelse:</strong></h6>

      <p>
      Ta av og kjør Sundlivegen, FV 6488
(ved Orkdal Kirke). Følg denne i ca
6,5 km. Her kan du parkere ved
fylkesveien eller du kan betale
bompenger (20 kr) og følge
Rørtjønnvegen i ca 2 km til
parkeringsplass ved Rørtjønna. Det
er også flott å sykle denne
veistrekningen
      </p>
  
      <h6><strong>Postbeskrivelse:</strong></h6>
      <p>
      Turmålet ligger i vår nabokommune
Skaun. Fra parkeringsplassen går
turen etter skogsbilvei og sti helt
mot slutten. Det er litt over 200 m
stigning fra Rørtjønna og opp til
posten. Ved posten er det benker
hvor du kan nyte den flotte utsikten.
Total distanse tur/retur: Ca 4 eller 8
km alt etter hvor du parkerer.
      </p>
      <h6><strong>Bilder:</strong></h6>
      <img src={`${process.env.PUBLIC_URL}/assets/icon/storlia2.png`} alt="storlia2" />
      <img src={`${process.env.PUBLIC_URL}/assets/icon/storlia.png`} alt="storlia" />
      </div >
 

  <IonButton  href='tab2'>Tilbake til HovedSide</IonButton>

  <IonButton  href='MyMap'>Se posten på Kartet</IonButton>


  <div className="button-wrapper">
  <IonButton href='Pilegrimsleia'>forrige post
  <IonIcon slot="start" icon={chevronBackOutline} />  
  </IonButton>
  <IonButton href='Storfurua'>neste post
  <IonIcon slot="end" icon={chevronForwardOutline}/>
  </IonButton>
  </div>

</body>
</IonPage>
  );
};

export default Storlia;

