import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonLabel, IonButton, IonItem, IonIcon, IonText, IonInput } from '@ionic/react';
import { flagOutline, returnDownBackOutline } from 'ionicons/icons';
import './styles.css';
import {chevronForwardOutline, chevronBackOutline }   from 'ionicons/icons';



const Ellingsgardstjonna: React.FC = () => {
 

  return (
    <IonPage>
      <body className="poster-page ion-padding">
        <h1>Post 3 – Ellingsgardstjønna</h1>
        <div>
          <h6><strong>Veibeskrivelse:</strong></h6>
            <p>
            Kjør E39 mot Søvasskjølen. I krysset på Stokkhaugen der fv714 tar av til Hitra,
            tar du av og kjører til Songlia. Parkeringsplass like bak hovedbygningen. Gå
            noen meter tilbake langs veien og følg merket sti gjennom skogen, noe stigning
            i starten, men vakker utsikt over Songsjøen. Et stykke oppe i bakken svinger
            stien bortom ei furu som har så tjukk stamme at det må tre mann til for å ta
            rundt den. Etter hvert blir det litt flatere og mer myr, men da er du straks
            framme. En gapahuk og en trivelig bålplass der du finner posten gir ekstra
            trivsel når du tar matpakken. Turen er 1,9 km fra parkeringen til tjønna, én vei.
            Du kan velge å gå en annen vei tilbake, den er også godt merket av Grytdalens
            venner. Da blir det en fin rundtur og enda mer utsikt på hjemturen. Den veien er
            2,6 km.
            </p>
            <h6><strong>Bilder:</strong></h6>
      <img src={`${process.env.PUBLIC_URL}/assets/icon/ellingsgardstjonna.png`} alt="ellingsgardstjonna" />
      <img src={`${process.env.PUBLIC_URL}/assets/icon/ellingsgardstjonna2.png`} alt="ellingsgardstjonna2" />
        </div>

  <IonButton href='tab2'>Tilbake til HovedSide</IonButton>

  <IonButton href='MyMap'>Se posten på Kartet</IonButton>

  <div className="button-wrapper"> 
  <IonButton href='Djupdalsfjellet'>forrige post
  <IonIcon slot="start" icon={chevronBackOutline}  />  
  </IonButton>

  <IonButton href='Rundhaugen'>neste post
  <IonIcon slot="end" icon={chevronForwardOutline}  />
  </IonButton>
  </div>
  </body>
</IonPage>
  );
};

export default Ellingsgardstjonna;