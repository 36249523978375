

import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonLabel, IonButton, IonItem, IonIcon, IonText, IonInput } from '@ionic/react';
import { flagOutline, returnDownBackOutline } from 'ionicons/icons';
import './PraktiskeOpplysninger.css';
import './variables.css';
import './Tab2.css';





const PraktiskeOpplysninger: React.FC = () => {
 

  return (
    <IonPage >

<IonContent fullscreen >
<h1>Praktiske opplysninger</h1>
  <div className="ion-padding tab2" >
    <IonText >
      <p>
        <strong>Kjentmannsmerkebrosjyren</strong> inneholder informasjon
om hver post, med plassering, veibeskrivelse og
hvordan du kommer dit. I konvolutten ligger også
registreringskort.
      </p>
      <p>
        <strong >Postene består av ei lita tretavle</strong> med nr. og navn på
posten, stifte-klemme til registrering, og ofte også
informasjon om stedet du står på.
      </p>
      <p>
        <strong>Registreringskort</strong>består av nr. og navn på posten og
et felt som du skal klippe i med stifteklemmen som
henger på posten. Du kan kjøpe ekstra registreringskort
til familiemedlemmer.
      </p>
      <p>
        <strong>Premiering,</strong>alle som har besøkt minst ti poster og leverer inn registreringskortet sitt
ved Orkland bibliotek, Orkanger innen 31.10.2024, er med i trekningen om fire
gavekort pà kr. 500,-.
      </p>
      <p>
        <strong>Pris</strong>Informasjonspakke med 1 kort: kr. 100,-. Ekstra registreringskort: kr. 20,-
      </p>
        
    </IonText>
  </div>
  <IonButton style={{  }} href='tab2'>Tilbake til HovedSide</IonButton>
</IonContent>
</IonPage>
  );
};

export default PraktiskeOpplysninger;