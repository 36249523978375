import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonLabel, IonButton, IonItem, IonIcon, IonText, IonInput } from '@ionic/react';
import { flagOutline, returnDownBackOutline } from 'ionicons/icons';
import './styles.css';
import {chevronForwardOutline, chevronBackOutline }   from 'ionicons/icons';


const Hogberget: React.FC = () => {
 

  return (
    <IonPage >
      <body className="poster-page ion-padding">

   
   
    <h1>Post 9 - Høgberget</h1>
   

    <div >


    
      <p>
      Kjør mot Svorkmo. Like etter Groven Gartneri, ta av Asbølljårvegen og kjør til
Orkdal Bygdetun, Oppstuggu Berbu. Skilta parkering. Følg merket trasé. Gå
gjerne runden «mot klokka» da det er litt bratt siste biten opp til posten når du
kommer fra øst. Kan være glatt når det regner.
Etter posten går stien gjennom et plantefelt på nordsida berget. Her tar vi hensyn
og holder oss mest mulig på stien. Flott utsikt mot Togstadjåren.
      </p>
  
      <p>
      Dette er en kort og
lett runde, ca 2 km,
og den som ønsker
en litt lengre tur, kan
gjerne ta en avstikker
til idylliske Røsvatnet
ved å ta til høyre like
etter at hytte er
passert. Der finner du
bl.a tilrettelagt
bålplass ved vatnet.
      </p>
      <h6><strong>Bilder:</strong></h6>
      <img src={`${process.env.PUBLIC_URL}/assets/icon/hogberget2.png`} alt="hogberget2" />
      <img src={`${process.env.PUBLIC_URL}/assets/icon/hogberget.png`} alt="hogberget" />
      </div >
  
 
  <IonButton  href='tab2'>Tilbake til HovedSide</IonButton>

  <IonButton href='MyMap'>Se posten på Kartet</IonButton>

  
  <div className="button-wrapper">
  <IonButton href='Hostovatnet_rundt'>forrige post
  <IonIcon slot="start" icon={chevronBackOutline}/>  
  </IonButton>
  <IonButton href='Pilegrimsleia'>neste post
  <IonIcon slot="end" icon={chevronForwardOutline} />
  </IonButton>
  </div>

</body>
</IonPage>
  );
};

export default Hogberget;