import { useHistory } from "react-router";
import './variables.css';
import './LogInn/StylesLogin.css';

function LagetAv() {

    const history = useHistory(); 

  const HandleButtonClick = () => {
    history.push('/tab3');
  }
    

    return(
        <html className="tab3">
            <body  >
                <div >
                    <h1><strong>Laget av:</strong></h1>
                    <br></br>
                    <p>
                        Elever på
                        <br></br>
                        Meldal Videregående Skole
                        <br></br>
                        VG3 - Dataelektronikerfaget
                        <br></br>
                        Skoleåret 2022-2023
                    </p>
                    <br></br>
                    <p>
                        Tusen takk for at du bruker denne appen
                    </p>
                    <br></br>
                    <button onClick={HandleButtonClick}>Tilbake</button>
                   
                </div>
            </body>
        </html>
    );
};

export default LagetAv;