import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonLabel, IonButton, IonItem, IonIcon, IonText, IonInput } from '@ionic/react';
import { flagOutline, returnDownBackOutline } from 'ionicons/icons';
import './styles.css';
import {chevronForwardOutline, chevronBackOutline }   from 'ionicons/icons';


const Pilegrimsleia: React.FC = () => {
 

  return (
<IonPage >
      <body className="poster-page ion-padding">

  
    <h1>Post 10- Pilegrimsleia fra Hongslo</h1>
    <div >


      <p>
      Fra Fannrem kjører du Fv. 6490
gjennom Blåsmo, til Solbu og
oppover til Monsetjåren. Kjør forbi
veien til Solsjøbygda til parkering
ved Hongslo Skytebane.
      </p>
  
      <p>
      Gå veien ca. 1 km, ta av til
venstre og gå stien til den møter
Solsjøveien ved Sagbakkan.
Retur samme vei.
      </p>
      <p>
      Posten ligger ved stien etter ca. 2
km. Fin sti hele veien.
      </p>
      <p>
      Like etter at du krysser kraftlinja
ligger Brennvinsbakken. Der er
det rester etter en
husmannsplass. Tvilsomt om det
var drikkestasjon for
pilegrimene...!
      </p>

      <p>
      Mulig å følge pilegrimsleia videre over Skaunakjølen til Trondheim.
Vår oppmerka tur er 5 km tur /retur. 100 m stigning.
      </p>
      <h6><strong>Bilder:</strong></h6>
      <img src={`${process.env.PUBLIC_URL}/assets/icon/pilegrimsleia.png`} alt="pilegrimsleia" />
      <img src={`${process.env.PUBLIC_URL}/assets/icon/pilegrimsleia2.png`} alt="pilegrimsleia2" />
      </div >
        
 

  <IonButton  href='tab2'>Tilbake til HovedSide</IonButton>

  <IonButton  href='MyMap'>Se posten på Kartet</IonButton>
 
  
  <div className="button-wrapper">
  <IonButton href='Hogberget'>forrige post
  <IonIcon slot="start" icon={chevronBackOutline}/>  
  </IonButton>
  <IonButton href='Storlia'>neste post
  <IonIcon slot="end" icon={chevronForwardOutline} />
  </IonButton>
  </div>

</body>
</IonPage>
  );
};

export default Pilegrimsleia;

