import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonLabel, IonButton, IonItem, IonIcon, IonText, IonInput } from '@ionic/react';
import { flagOutline, returnDownBackOutline } from 'ionicons/icons';
import './styles.css';
import {chevronForwardOutline, chevronBackOutline }   from 'ionicons/icons';


const Stuggudalstjonnin: React.FC = () => {
 

  return (
    <IonPage >
<body className="poster-page ion-padding">
   
   
    <h1>Post 7 - Stuggudalstjønnin</h1>
    <div >




    
      <p>
      Kjør til Hoston. Følg Hostovegen mot Laksøybygda. Etter å ha passert
Hostovatnet, ta av til høyre på Ringaveien (6170). Ta til venstre i neste kryss
og følg Lomundalsvegen fram til Risegrenda hvor det er parkering på venstre
side av vegen.
<br></br>
Derfra starter merket gåtur opp Stuggudalsvegen.
      </p>
  
      <p>
      Gå veien innover forbi Groeggsætrene til Sandkollen. Derfra merka sti til
Stuggudalstjønnin. Posten er ved Fremre Stuggudalstjønna.
      </p>
 
      <p>
      Det er to tjønner helt inntil hverandre, den lenger inn heter Midttjønna, noe
som tilsier at det er ei tredje tjønn nærmere Søvatnet. Stuggudalen er et
område der det har vært drevet mye sæterdrift, men kanskje enda mer et
område for utmarksslått. Det ble slått gras om sommeren som ble tørket og
lagret i hulluer for så å bli kjørt heim til gårder i Hoston, Laksøybygda og
Svorkbygda på vinteren. Dette var kjente gårder som Hoston, Groegga (flere),
Meåsen (to), Viken og Lykkja.
Flere av hulluene er i dag restaurert og disse ligger gjerne litt høyere opp i
retning Slettfjellet.
      </p>
    
      <p>
      Turen er ca 5-6 km tur-retur,
beskjeden stigning.
      </p>
      <h6><strong>Bilder:</strong></h6>
      <img src={`${process.env.PUBLIC_URL}/assets/icon/stuggudalstjonnin.png`} alt="stuggudalstjonnin" />
      <img src={`${process.env.PUBLIC_URL}/assets/icon/stuggudalstjonnin2.png`} alt="stuggudalstjonnin2" />
      </div >

      
    
              
        


  <IonButton  href='tab2'>Tilbake til HovedSide</IonButton>
 
  <IonButton  href='MyMap'>Se posten på Kartet</IonButton>

  
  <div className="button-wrapper">
  <IonButton href='Vardfjellet'>forrige post
  <IonIcon slot="start" icon={chevronBackOutline} />  
  </IonButton>
  <IonButton href='Hostovatnet_rundt'>neste post
  <IonIcon slot="end" icon={chevronForwardOutline}  />
  </IonButton>
  </div>

</body>
</IonPage>
  );
};

export default Stuggudalstjonnin;