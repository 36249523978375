import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonLabel, IonButton, IonItem, IonIcon, IonText, IonInput } from '@ionic/react';
import { flagOutline, returnDownBackOutline } from 'ionicons/icons';
import './styles.css';
import {chevronForwardOutline, chevronBackOutline }   from 'ionicons/icons';


const Rundhaugen: React.FC = () => {
 

  return (
    <IonPage >
    <body className="poster-page ion-padding">

    <h1>Post 4 - Rundhaugen</h1>
    <div>

      <h6><strong>Veibeskrivelse:</strong></h6>
      <p>

Kjør E39 mot Søvasskjølen. Ta av mot
Hitra når du kommer til Stokkhaugen. Følg
FV 714 i ca 3 km og parker ved
parkeringsskiltet, ved et kommunalteknisk
bygg. Vis hensyn og ikke parker foran
huset.
      </p>

      <h6><strong>Postbeskrivelse:</strong></h6>
      <p>
      Turen starter over ei myr for så å følge
Preikstolbergvegen gjennom hyttefeltet
opp til et kommunalt høydebasseng. Følg
deretter merkene frem til turmålet. Her er
det flott utsikt i alle himmelretninger. Ca
170 m stigning fra parkering til turmålet.
Total distanse tur/retur: Ca 4 km.
      </p>
      <h6><strong>Bilder:</strong></h6>
      <img src={`${process.env.PUBLIC_URL}/assets/icon/rundhaugen.png`} alt="rundhaugen" />
      <img src={`${process.env.PUBLIC_URL}/assets/icon/rundhaugen2.png`} alt="rundhaugen2" />
      </div>

  <IonButton  href='tab2'>Tilbake til HovedSide</IonButton>
  <IonButton  href='MyMap'>Se posten på Kartet</IonButton>
  
  <div className="button-wrapper">
  <IonButton href='Ellingsgardstjonna'>forrige post
  <IonIcon slot="start" icon={chevronBackOutline}  />  
  </IonButton>

  <IonButton href='Svartholhausen'>neste post
  <IonIcon slot="end" icon={chevronForwardOutline}  />
  </IonButton>
  </div>
</body>
</IonPage>
  );
};

export default Rundhaugen;