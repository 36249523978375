import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonLabel, IonButton, IonItem, IonIcon } from '@ionic/react';
import { flagOutline, returnDownBackOutline } from 'ionicons/icons';


const Poster: React.FC = () => {
 

  return (
    <IonPage>
        <IonContent>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>
                        Poster
                    </IonTitle>
                </IonToolbar>
            </IonHeader>

          <IonItem>
          <IonLabel style={{ fontSize: "15px", }}>Post 1 Furtjønna</IonLabel>
          <IonButton style={{ width: '60px', height:'35px' }}color="tertiary" href="/Furtjonna">
          <IonIcon icon={flagOutline} size="large"></IonIcon>
          </IonButton>
          </IonItem>

          <IonItem>
          <IonLabel style={{ fontSize: "15px" }}>Post 2 Djupdalsfjellet</IonLabel>
          <IonButton style={{ width: '60px', height:'35px' }}color="tertiary" href="/Djupdalsfjellet">
          <IonIcon icon={flagOutline} size="large"></IonIcon>
          </IonButton>
          </IonItem>

          <IonItem>
          <IonLabel style={{ fontSize: "15px" }}>Post 3 Ellingsgardstjønna</IonLabel>
          <IonButton style={{ width: '60px', height:'35px' }}color="tertiary" href="/Ellingsgardstjonna">
          <IonIcon icon={flagOutline} size="large"></IonIcon>
          </IonButton>
          </IonItem>

          <IonItem>
          <IonLabel style={{ fontSize: "15px" }}>Post 4 Rundhaugen</IonLabel>
          <IonButton style={{ width: '60px', height:'35px' }}color="tertiary" href="/Rundhaugen">
          <IonIcon icon={flagOutline} size="large"></IonIcon>
          </IonButton>
          </IonItem>

          <IonItem>
          <IonLabel style={{ fontSize: "15px" }}>Post 5 Svartholhausen</IonLabel>
          <IonButton style={{ width: '60px', height:'35px' }}color="tertiary" href="/Svartholhausen">
          <IonIcon icon={flagOutline} size="large"></IonIcon>
          </IonButton>
          </IonItem>

          <IonItem>
          <IonLabel style={{ fontSize: "15px" }}>Post 6 Vardfjellet</IonLabel>
          <IonButton style={{ width: '60px', height:'35px' }}color="tertiary" href="/Vardfjellet">
          <IonIcon icon={flagOutline} size="large"></IonIcon>
          </IonButton>
          </IonItem>

          <IonItem>
          <IonLabel style={{ fontSize: "15px" }}>Post 7 Stuggudalstjønnin</IonLabel>
          <IonButton style={{ width: '60px', height:'35px' }}color="tertiary" href="/Stuggudalstjonnin">
          <IonIcon icon={flagOutline} size="large"></IonIcon>
          </IonButton>
          </IonItem>

          <IonItem>
          <IonLabel style={{ fontSize: "15px" }}>Post 8 Hostovatnet rundt</IonLabel>
          <IonButton style={{ width: '60px', height:'35px' }}color="tertiary" href="/Hostovatnet_rundt">
          <IonIcon icon={flagOutline} size="large"></IonIcon>
          </IonButton>
          </IonItem>

          <IonItem>
          <IonLabel style={{ fontSize: "15px" }}>Post 9 Høgberget</IonLabel>
          <IonButton style={{ width: '60px', height:'35px' }}color="tertiary" href="/Hogberget">
          <IonIcon icon={flagOutline} size="large"></IonIcon>
          </IonButton>
          </IonItem>

          <IonItem>
          <IonLabel style={{ fontSize: "15px" }}>Post 10 Pilegrimsleia fra Hongslo</IonLabel>
          <IonButton style={{ width: '60px', height:'35px' }}color="tertiary" href="/Pilegrimsleia">
          <IonIcon icon={flagOutline} size="large"></IonIcon>
          </IonButton>
          </IonItem>

          <IonItem>
          <IonLabel style={{ fontSize: "15px" }}>Post 11 Storlia</IonLabel>
          <IonButton style={{ width: '60px', height:'35px' }}color="tertiary" href="/Storlia">
          <IonIcon icon={flagOutline} size="large"></IonIcon>
          </IonButton>
          </IonItem>

          <IonItem>
          <IonLabel style={{ fontSize: "15px" }}>Post 12 Storfurua i Husdalen</IonLabel>
          <IonButton style={{ width: '60px', height:'35px' }}color="tertiary" href="/Storfurua">
          <IonIcon icon={flagOutline} size="large"></IonIcon>
          </IonButton>
          </IonItem>

          <IonItem>
          <IonLabel style={{ fontSize: "15px" }}>Tilbake til Meny</IonLabel>
          <IonButton style={{ width: '60px', height:'35px' }}color="danger" href="/tab2">
          <IonIcon icon={returnDownBackOutline} size="large"></IonIcon>
          </IonButton>
          </IonItem>
                 
        </IonContent>
    </IonPage>
    
  );
};

export default Poster;
