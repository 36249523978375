import React from 'react';
import { 
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonList,
  IonMenu,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonLabel,
  IonButton,
  IonIcon,
  IonText
  
  
} from '@ionic/react';

import DefaultHeader from '../DefaultHeader';

import { logOutOutline,
         flagOutline,
        alertCircleOutline } from 'ionicons/icons';

        import './Tab2.css';
        import '../App.css';
       

function Tab2() {
  return (
    
  <>
      <IonMenu contentId="main-content">
        <IonHeader>
          <IonToolbar>
          <IonButtons slot="start">
              <IonMenuButton color="Dark"></IonMenuButton>
            </IonButtons>
            <IonTitle>Utforsk</IonTitle>
          </IonToolbar>
        </IonHeader>

        {/*Meny sidebar Content*/ }
        <IonContent  fullscreen={true} className="ion-padding mainpage"> 
        
          
          <IonItem>
          <IonLabel style={{ fontSize: "16px", }}>Poster</IonLabel>
          <IonButton style={{ width: '60px', height:'35px', }}color="tertiary" href="/Poster">
          <IonIcon icon={flagOutline} size="large"></IonIcon>
          </IonButton>
          </IonItem>
          

          <IonItem>
          <IonLabel style={{ fontSize: "16px", }}>Praktiske Opplysninger</IonLabel>
          <IonButton style={{ width: '60px', height:'35px' }}color="tertiary" href="/PraktiskeOpplysninger">
          <IonIcon icon={alertCircleOutline} size="large"></IonIcon>
          </IonButton>
          </IonItem>

        </IonContent>

      </IonMenu>
      <IonPage  id="main-content">
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuButton color="Dark"></IonMenuButton>
            </IonButtons>
            <IonTitle>Meny</IonTitle>
          </IonToolbar>
        </IonHeader>
    
        
       
       
      <IonContent fullscreen>
        
        <div className="ion-padding tab2" >
        <h1>Kjentmannsmerket i Orkdal</h1>
          <h6><strong>-et prosjekt i trimavdelinga i Orkdal Idrettslag</strong></h6>
          <IonText>
            <p>
              <strong>Når du gjennomfører Kjentmannsmerket</strong> får du stadig nye mål for turene dine; du
              kommer deg ut, og det blir enklere å legge turen i ukjent terreng. Om du klarer å
              besøke alle postene, vil du få oppleve noe av naturens mangfold i vårt distrikt, og vi
              håper og tror du vil få mange flotte turer! Vi anbefaler deg å ta med kikkert.
            </p>
            <p>
              <strong>Postene i Kjentmannsmerket</strong> er spredt over hele gammelkommunen Orkdal. De
              har varierende vanskegrad, og varierende lengde fra veg/parkering. De 12 postene
              som er beskrevet i denne brosjyren står ute fra slutten av mai 2023 til 1. okt. 2024.
            </p>
            <p>
              <strong>Hvis du under turen oppdager at noe er galt</strong> med posten eller merkingen, blir vi
              glade om du melder fra om dette til:
            </p>
            <ul>
              <li>Ivar Nerhoel, tlf. 905 80 919</li>
              <li>Trine Sølberg, tlf. 408 52 234</li>
            </ul>
            <p>
              Eller du kan sende oss en melding på{' '}
              <a href="https://www.facebook.com/kjentmannsmerketorkdal" target="_blank" rel="noopener noreferrer">
                Facebook
              </a>
              .
            </p>
          </IonText>
          <br></br>
          <img alt="bilde" id="image" src = "https://www.orkdal-il.no/assets/Uploads/Illustrasjon-Kjentmannsmerket.jpg" />
      
        </div>
        
      </IonContent>
      </IonPage>
    </>
  );
}
export default Tab2;

