import { useState } from 'react';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { useHistory, useLocation } from 'react-router-dom';
import './StylesLogin.css';

function LogIn() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);

  const auth = getAuth();
  const history = useHistory();
  const location = useLocation();

  const handleLogin = () => {
    signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        const redirectUrl = window.sessionStorage.getItem('redirectUrl');
        if (redirectUrl) {
          window.sessionStorage.removeItem('redirectUrl');
          history.push(redirectUrl);
        } else {
          history.replace(location.state?.from || '/tab2');
        }
      })
      .catch((error) => {
        setErrorMessage(error.message);
      });
  };

  const handleGoBack = () => {
    history.push('/WelcomePage');
  };

  // Check if referrer URL contains "register"
  const referrerUrl = document.referrer;
  if (referrerUrl.includes("register")) {
    window.sessionStorage.setItem('redirectUrl', referrerUrl);
  }


  return (
    <div className='loginstuff'>
      <h1>Logg inn:</h1>
      <div>
        <label>E-post:</label>
        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
      </div>
      <div>
        <label>Passord:</label>
        <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
      </div>
      <button onClick={handleLogin}>Logg inn</button>
      {errorMessage && <p type="errormessage">{errorMessage}</p>}
      <button onClick={handleGoBack}>Tilbake</button>
    </div>
  );
}

export default LogIn;
