import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonTabBar, IonButton, useIonRouter, } from '@ionic/react';



const Tab1: React.FC = () => {
 

  return (
    <IonPage>
      <IonContent>
      <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100vh"}}>

      <IonButton style={{ font: 'burbank', fontSize: "30px", width: '250px', height: '50px' }} href ="/MapPage" >Kart</IonButton>
    </div>
      </IonContent>
    </IonPage>
    
  );
};

export default Tab1;
