import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonLabel, IonButton, IonItem, IonIcon, IonText, IonInput } from '@ionic/react';
import { flagOutline, returnDownBackOutline } from 'ionicons/icons';
import './styles.css';
import {chevronForwardOutline, chevronBackOutline }   from 'ionicons/icons';


const Furtjonna: React.FC = () => {
 

  return (
    <IonPage >
      <body className="poster-page ion-padding">

      

  
    <h1>Post 1 – Furtjønna</h1>
    <div >
    
    

      <h6><strong>Veibeskrivelse:</strong></h6>
      <p>
      Kjør FV 710 til Geitastranda og ta av på Husdalsvegen. Kjør til Fjellkjøsvatnet
      og parker på parkeringsplassen. Det er bomavgift ved Kvernamoen. Det er ny
      bomløsning. Se{' '}
              <a href="https://www.youpark.no" target="_blank" rel="noopener noreferrer">
                https://www.youpark.no
              </a>
              .
              <br></br>
              (Søk etter Fjellkjøsen). Kr 60 pr enkelttur.
      </p>
      <h6><strong>Postbeskrivelse:</strong></h6>
      
      <p>
        Gå ca 2 km langs Brølivegen og ta til høyre
        rett før Brølibekken. Herfra går turen delvis
        langs godt etablert sti, og du passerer
        Brølitjønna før du ender opp ved turmålet
        Furtjønna. Ca 100 m stigning fra parkeringa
        til turmålet. Deler av turen går over myr så
        det kan være noe bløtt. Total distanse
        tur/retur: Ca 8 km.
        Fint å sykle de første 2 km etter vei.
      </p>
      <h6><strong>Bilder:</strong></h6>
      <img src={`${process.env.PUBLIC_URL}/assets/icon/furtjonna.png`} alt="furtjonna" />
      <img src={`${process.env.PUBLIC_URL}/assets/icon/furtjonna2.png`} alt="furtjonna2" />
      </div>
   

    
        

  <IonButton  href='tab2'>Tilbake til HovedSide</IonButton>

  <IonButton  href='MyMap'>Se posten på Kartet</IonButton>
 
 
  <div className="button-wrapper-right">
  <IonButton href='Djupdalsfjellet'>neste post
    <IonIcon slot="end" icon={chevronForwardOutline} />
  </IonButton>
</div>

 

</body>
</IonPage>
  );
};

export default Furtjonna;