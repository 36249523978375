import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getDocs, collection, query, where, doc, setDoc, deleteDoc } from 'firebase/firestore';
import { db } from './firebase-config';
import './StylesLogin.css';

function WelcomePage() {
  const [code, setCode] = useState('');
  const [codeValid, setCodeValid] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const history = useHistory();

  const handleCodeSubmit = async (e) => {
    e.preventDefault();
    const codesCollectionRef = collection(db, 'Koder');
    const codeQuery = query(codesCollectionRef, where('Code', '==', code));
    const querySnapshot = await getDocs(codeQuery);
    const codeData = querySnapshot.docs.map((doc) => doc.data());
    const validCode = codeData[0];
    if (validCode) {
      setCodeValid(true);
      setErrorMessage('');

      // Mark the code as used
      const usedCodesCollectionRef = collection(db, 'BrukteKoder');
      const codeDocRef = doc(usedCodesCollectionRef, code);
      await setDoc(codeDocRef, { used: true });

      // Remove the code from the original collection
      const originalCodeDocRef = querySnapshot.docs[0].ref;
      await deleteDoc(originalCodeDocRef);
    } else {
      setCodeValid(false);
      setErrorMessage('Feil kode, prøv igjen');
    }
  };

  const handleSignUpButtonClick = () => {
    history.push('/SignUp');
  };

  return (
    <div className="loginstuff">
      <h1><strong>Velkommen!</strong></h1>
      <p>Her kan du registrere deg med koden du har fått av kjentmannsmerket</p>
      <form onSubmit={handleCodeSubmit}>
        <input
          type="text"
          placeholder="Skriv inn koden her:"
          value={code}
          onChange={(event) => setCode(event.target.value)}
        />
        <button type="submit">OK</button>
      </form>
      {errorMessage && (
        <p style={{color: 'red'}} className="errormessage">{errorMessage}</p>
      )}
      {codeValid && (
        <>
          <p style={{color: 'green'}} >Riktig kode, Opprett bruker her</p>
          <button type="OprettBruker" onClick={handleSignUpButtonClick}>Oprett bruker:</button>
        </>
      )}
      <p style={{color: 'black'}}>Allerede registrert? Logg inn her:</p>
      <button style={{ marginTop: "20px" }} onClick={() => history.push('/LogIn')}>
        Logg inn
      </button>
    </div>
  );
}

export default WelcomePage;


